<template>
  <div>
    <div class="search_area">
      <div class="container">
        <div class="search-inside-container">
          <div class="search_box flex-1 pr-10 ">
            <v-text-field
              prepend-icon="fal fa-search"
              placeholder="Search"
              class="search_input"
              v-model="search"
              clearable
              @change="optimizePage()"
              @clear="optimizePage()"
            ></v-text-field>
          </div>
          <div class="search_image">
            <img src="@/assets/images/search-banner.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="knowledgebase-container">
 
      <v-container>
        <v-row>
          <template   v-for="cat in mainCategories">
          <v-col
            :md="4"
            :lg="4" 
            v-if="cat.children.length > 0"
            :key="cat.id"
          >
      
            <div class="box">
              <div class="box_header">
                <i class="fal fa-folder-open"></i>
                {{ cat.title }}
                {{ cat.childCount ? "(" + cat.children.length + ")" : "" }}
              </div>
              <div class="box_content">
                <div
                  v-for="(item, i) in cat.children"
                  @click="
                    $router.push({ path: '/knowledge-base/' + item.collectionId + '/' + item.pageId })
                  "
                  class="box_item"
                  :class="cat.isLimited && i > 2 ? 'box_not_visible' : ''"
                >
                  {{ item.title }}
                </div>
                <div v-show="cat.children.length>3"
                  class="box_item last"
                  @click="cat.isLimited = !cat.isLimited"
                >
                 {{ cat.isLimited ?  $t('Show All') : $t('Minimize')}}
                </div><div v-show="cat.children.length<=3"
                  class="box_item last"   >
                 
                </div>
              </div>
            </div>
          </v-col>
          </template>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script  >
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "KnowledgeBase",

  data() {
    return {
      loading: false,
      pageDataGrouped: {},
      mainCategories: [],
      search: "",
    };
  },
  created() { 

    setTimeout(() => {
          if(!this.mainData['knowledge_base']){ 
            if(!this.isLogin){
              this.$router.push({ name: "signIn" }); 
            }else{
                this.$router.push({ name: "tickets" }); 
            }
          }else{
              this.pages();
          }
    }, 500);
  
  },
  computed: {
    ...mapGetters('account', ['isLogin']),

    pageData() {
      return this.$store.state.common.pages;
    },
    pagesLoaded() {
      return this.$store.state.common.pagesLoaded;
    },
     mainData() {
      return this.$store.state.common.main;
    },
  },
  methods: {
    async pages() {
      //   https://cloud.kiva.app/support/pages/list?kb=15&uid=n4SKeaSmpqSdmg,,
      await this.$store.dispatch("common/knowledgebasePages", {});
      //    this.optimizePage();
    },
    getmainCategories(i) {
      return this.mainCategories.filter((a, index) => {});
    },
    optimizePage() {
      this.loading = true;
      this.pageDataGrouped = {};
      let pageDataCopy = this.pageData.filter((k) => {
        let s = this.search ? this.search.toLocaleUpperCase() : "";
        let t = k.title.toLocaleUpperCase();
        if (k.parentPageId == null || this.search == "" || t.search(s) > -1) {
          return true;
        } else {
          return false;
        }
      });

      let pageDataCopy2 = pageDataCopy.map((k) => {
        return { ...k, children: [], isLimited: true };
      });

      pageDataCopy2.forEach((k) => {
        if (k.parentPageId) {
          if (!this.pageDataGrouped[k.parentPageId]) {
            this.pageDataGrouped[k.parentPageId] = [];
          }
          this.pageDataGrouped[k.parentPageId].push(k);
        }
      });

      // console.log(" this.pageDataGrouped", this.pageDataGrouped)
      let pageDataCopy3 = pageDataCopy2.map((k) => {
        if (
          this.pageDataGrouped &&
          this.pageDataGrouped[k.pageId] &&
          this.pageDataGrouped[k.pageId][0] &&
          this.pageDataGrouped[k.pageId][0].pageId
        ) {
          //    console.log("this.pageDataGrouped[k.parentPageId]",this.pageDataGrouped[k.parentPageId])
          // this.pageDataGrouped[k.parentPageId].forEach(b=> k.children.push(b))
          k.children = this.pageDataGrouped[k.pageId];
          return k;
        }
        return k;
      });

      this.mainCategories = pageDataCopy3.filter(
        (k) => k && k.parentPageId == null
      );
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
  watch: {
    pagesLoaded(val) {
      if (!val) {
        this.optimizePage();
      }
    },
  },
};
</script>
<style lang="scss">
 .search_box { 
    .v-input__prepend-outer{
      margin-top:2px !important;
    }
  }
  </style>
<style lang="scss" scoped>
.knowledgebase-container {
  background: #f6f8fc;
  width: 100%;
  padding: 20px;
  .box {
    height: auto;
    min-height: 189px;
    background: #ffffff;
    align-items: center;
    padding: 5px 0px;
    border: 1px solid #d9e1ee;
    box-sizing: border-box;
    border-radius: 5px;
    .box_header {
      border-bottom: 1px solid #d9e1ee;
      width: 100%;
      min-width: 200px;
      padding: 10px 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #465669;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .box_content {
      // padding-bottom: 20px;
      padding: 10px;
    }
    .box_item {
      padding: 5px 0px;
      border-bottom: 0.5px dashed #d9e1ee;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      cursor: pointer;

      &.last {
        border: 0;
        cursor: pointer;
        text-align: right;
        color: #2887eb;
      }
    }
    .box_not_visible {
      display: none;
    }
  }
}
.search_area {
  // background: #91c9fd;
  background: var(--v-primary-base-light) !important;
  height: 250px;
  width: 100%;
  margin-bottom: 10px;

  padding: 0px 20px;

  .search-inside-container {
    width: 100%;

    display: inline-flex;
    justify-content: space-between;
  }
  .search_box {
    padding: 90px 0px;
    .search_input {
      background: #fff;
      height: 40px;
      padding: 7px;
      border-radius: 10px;
      min-width: 350px;
    }
    .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
      border: 0px solid #fff !important;
    }
    .v-input__prepend-outer{
      margin-top:2px !important;
    }
  }

  .search_image {
    img {
      height: 238px;
      margin-top: 20px;

    }
  }
}
</style>
<style>
.search_area
  .theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: rgba(0, 0, 0, 0) !important;
  border-top-color: rgba(0, 0, 0, 0) !important;
  border-right-color: rgba(0, 0, 0, 0) !important;
  border-bottom-color: rgba(0, 0, 0, 0) !important;
  border-left-color: rgba(0, 0, 0, 0) !important;
}
</style>